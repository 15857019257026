/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../../Styles/SelectStation.css";
import { Header, SelectStationDropDown, Loader } from "../index";

import "moment/locale/id";
moment.locale("id");
import { id } from "date-fns/locale"; // this is localization for Indonesian Language......

import { Button, Switch, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { getAirports } from "../../api/ApiUtils";
import { PopUpModal } from "../../CommonComponents/PopUp/PopUpModal";
import SliderButton from "../../CommonComponents/SliderButton/SliderButton";
import SelectPassengerPopUp from "./SelectPassengerPopUp";
import SelectPassengerClass from "./SelectPassengerClass";
import {
  convertArrayToObjectByKey,
  FLIGHT_TYPES,
  isDateAfter,
  passengerClassMap,
  priceFormatter,
} from "../../common/Utils";
import useLocalstorage, {
  APP_LOCAL_STORAGE_KEYS,
} from "../../hooks/useLocalstorage";
import { popularAirports } from "../../common/PopularAirports";
import logo from "./../../assets/logo.png";
import SourceFlightIcon from "./../../assets/source-flight.svg";
import DestinationFlightIcon from "./../../assets/destination-flight.svg";
import PeopleIcon from "./../../assets/people.svg";
import SeatIcon from "./../../assets/icon-seat.png";
import CalendarStartIcon from "./../../assets/calendar-start.png";
import CalendarEndIcon from "./../../assets/calendar-end.png";
import SwapIcon from "./../../assets/Swap.svg";

export const JOURNEY_TYPE = {
  OneWay: "ONE_WAY",
  RoundTrip: "ROUND_TRIP",
};

const StationSelection = () => {
  const classes = useStyle();
  const header = "Cari Tiket Pesawat";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [returnTicket, setReturnTicket] = useState(false);

  // STATE'S TO HANDLE THE SLIDERS TO SELECT THE STATION NAMES
  const [startStation, setStartStation] = useState("");
  const [openStartStationDropDown, setOpenStartStationDropDown] =
    useState(false);
  const [stopStation, setStopStation] = useState("");
  const [openStopStationDropDown, setOpenStopStationDropDown] = useState(false);
  const [openPassenger, setOpenPassenger] = useState(false);
  const [openPassengerClass, setOpenPassengerClass] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [passengers, setPassengers] = useState({
    adults: 1,
    infant: 0,
    child: 0,
  });
  const [passengerClass, setPassengerClass] = useState("ECONOMY");
  const [airports, setAirports] = useState([]);
  const { set: setLocalStorageValue } = useLocalstorage();

  useEffect(() => {
    async function fetchAirports() {
      setLoading(true);
      try {
        const resAirports = await getAirports();
        const airPortsByCode = convertArrayToObjectByKey(
          resAirports,
          "airportCode"
        );

        const airportCodesArray = Object.keys(airPortsByCode);
        popularAirports.reduce((obj, item) => {
          if (!airportCodesArray.includes(item.airportCode)) {
            obj[item["airportCode"]] = item;
            resAirports.push(item);
            return obj;
          }
        }, airPortsByCode);

        localStorage.setItem("KAI", JSON.stringify({ passengerList: [] }));
        setLocalStorageValue(
          APP_LOCAL_STORAGE_KEYS.airportsByCode,
          airPortsByCode
        );
        setAirports(resAirports);
      } catch (err) {
        console.log(err);
        showErrorPopUp({
          showPopUp: true,
          message: "Something went wrong! Please try again.",
        });
      }
      setLoading(false);
    }
    fetchAirports();
    localStorage.removeItem("INITIAL_FORM_DATA");
    localStorage.removeItem("KAI");
    localStorage.removeItem("ADDON");
    localStorage.removeItem("SELECTED_FLIGHT_DETAILS_ONE_WAY");
    localStorage.removeItem("SELECTED_FLIGHT_DETAILS_ROUND");
    let KAI = {};
    localStorage.setItem("KAI", JSON.stringify(KAI));
  }, []);

  // SET THE DEPARTURE DATE
  const [departureDate, setDepartureDate] = useState(new Date());
  // SET ARRIVAL DATE
  const [arrivalDate, setArrivalDate] = useState(new Date());

  useEffect(() => {
    if (isDateAfter(departureDate, arrivalDate) && returnTicket) {
      setArrivalDate(departureDate);
    }
  }, [departureDate, returnTicket]);

  const [error, setError] = useState(false);
  const handleSubmit = () => {
    if (isDateAfter(departureDate, arrivalDate) && returnTicket) {
      setError(true);
      return;
    }
    if (startStation && stopStation && startStation != stopStation) {
      setLocalStorageValue(APP_LOCAL_STORAGE_KEYS.initialFormData, {
        departureAirportDetails: startStation,
        arrivalAirportDetails: stopStation,
        arrDate: moment(arrivalDate).format("YYYY-MM-DD"),
        depDate: moment(departureDate).format("YYYY-MM-DD"),
        seatClass: passengerClass,
        passengers: {
          adult: passengers.adults,
          child: passengers.child,
          infant: passengers.infant,
        },
        journeyType: returnTicket
          ? JOURNEY_TYPE.RoundTrip
          : JOURNEY_TYPE.OneWay,
      });
      setLocalStorageValue(
        APP_LOCAL_STORAGE_KEYS.flightTypeForListing,
        FLIGHT_TYPES.oneWay
      );
      navigate(`/stationpricecard`);
    } else {
      setError(true);
    }
  };

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  };

  const getLocationLabel = (station) => {
    return station.localCityName + " (" + station.airportCode + ")";
  };

  const getPassengerNumber = () => {
    let passengerValue = "";
    if (passengers.adults > 0) {
      passengerValue = `${passengers.adults} Dewasa`;
    }
    if (passengers.child > 0) {
      passengerValue = passengerValue + `, ${passengers.child} Anak`;
    }
    if (passengers.infant > 0) {
      passengerValue = passengerValue + `, ${passengers.infant} Bayi`;
    }
    return passengerValue;
  };
  const maxSelectableDate = moment(new Date()).add(1, "years").toDate();

  const swapAirports = () => {
    const temp = startStation;
    setStartStation(stopStation);
    setStopStation(temp);
  };

  return (
    <div id="StationSelection" className={"StationSelection"}>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />

      {/* <Header header={header} back={""} step={"1"} /> */}

      <div id="StationSelectionForm">
        <div className="form-background">
          <div>
            <img src={logo} alt="traveloka" />
          </div>
        </div>
        <div className="form-input-box">
          <div className="input-box">
            {/* Select Dropdown for Station Names */}
            <div className="flex item-center">
              <img
                src={SourceFlightIcon}
                onClick={() =>
                  setOpenStartStationDropDown(!openStartStationDropDown)
                }
              />
              <TextField
                id={"asal-value"}
                fullWidth
                autoComplete={"off"}
                label={
                  !(openStartStationDropDown || openStopStationDropDown)
                    ? "Asal"
                    : ""
                }
                variant="filled"
                value={startStation ? getLocationLabel(startStation) : ""}
                error={error && !startStation}
                // className={classes.stationDropdownButton}
                onFocus={() =>
                  setOpenStartStationDropDown(!openStartStationDropDown)
                }
                className="bg-white"
                // style={{
                //   backgroundColor: "red",
                // }}
                InputProps={{
                  className: "bg-white",
                  disableUnderline: error && !startStation ? false : true,
                  // endAdornment: (
                  //   <div>
                  //     <KeyboardArrowDownIcon
                  //       className={"iconArrowInSelectInput  bg-white"}
                  //       onClick={() =>
                  //         setOpenStartStationDropDown(!openStartStationDropDown)
                  //       }
                  //     />
                  //   </div>
                  // ),
                }}
                inputProps={{
                  style: { fontWeight: "bold" },
                }}
                helperText={
                  error && !startStation
                    ? "Pilih penerbangan asal terlebih dahulu"
                    : ""
                }
                FormHelperTextProps={{
                  style: {
                    margin: "0",
                    color: "#c21616",
                    display: error ? "" : "none",
                  },
                }}
              />
            </div>
            <div className="swap-container">
              <div className="divider"></div>
              <div
                className="flex item-center swap-airport-icon"
                onClick={swapAirports}
              >
                <img src={SwapIcon} />
              </div>
            </div>

            <div className="flex item-center">
              <img
                src={DestinationFlightIcon}
                onClick={() =>
                  setOpenStopStationDropDown(!openStopStationDropDown)
                }
              />
              <TextField
                id={"Tujuan-value"}
                fullWidth
                autoComplete={"off"}
                label={
                  !(
                    openStartStationDropDown ||
                    openStopStationDropDown ||
                    openPassenger ||
                    openPassengerClass
                  )
                    ? "Tujuan"
                    : ""
                }
                variant="filled"
                value={stopStation ? getLocationLabel(stopStation) : ""}
                error={error && !stopStation}
                className={classes.stationDropdownButton}
                onFocus={() =>
                  setOpenStopStationDropDown(!openStopStationDropDown)
                }
                InputProps={{
                  className: "bg-white",
                  disableUnderline: error && !stopStation ? false : true,
                  // endAdornment: (
                  //   <div>
                  //     <KeyboardArrowDownIcon
                  //       className={"iconArrowInSelectInput bg-white"}
                  //       onClick={() =>
                  //         setOpenStopStationDropDown(!openStopStationDropDown)
                  //       }
                  //     />
                  //   </div>
                  // ),
                }}
                inputProps={{
                  style: { fontWeight: "bold" },
                }}
                helperText={
                  error && stopStation == ""
                    ? "Pilih penerbangan tujuan terlebih dahulu"
                    : stopStation != "" && stopStation == startStation
                    ? "Nama penerbangan tujuan tidak boleh sama dengan penerbangan Asal"
                    : ""
                }
                FormHelperTextProps={{
                  style: {
                    margin: "0",
                    color: "#c21616",
                    display: error ? "" : "none",
                  },
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "16px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "56px",
                  width: "100%",
                  borderRadius: "4px",
                  // background: "#F7F6F6",
                }}
                className="item-center"
              >
                {/* Flight Ticiket Departure Date */}
                {/* <CalendarTodayOutlinedIcon className={"color-app-blue"} /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={id}>
                  <MobileDatePicker
                    InputProps={{
                      className: "bg-white",
                      disableUnderline: true,
                      style: {
                        height: "56px",
                        borderRadius: "4px",
                        // background: "#F7F6F6",
                        fontWeight: "bold",
                      },
                      classes: { notchedOutline: classes.noBorder },
                    }}
                    disablePast
                    DialogProps={{ className: classes.selectDepartureDate }}
                    value={departureDate}
                    maxDate={maxSelectableDate}
                    cancelText={""}
                    label={
                      !(
                        openStartStationDropDown ||
                        openStopStationDropDown ||
                        openPassengerClass ||
                        openPassenger
                      )
                        ? "Tanggal Keberangkatan"
                        : ""
                    }
                    onChange={(date) => setDepartureDate(date)}
                    renderInput={(params) => {
                      params.inputProps.value = moment(
                        params.inputProps.value,
                        "DD/MM/YYYY"
                      ).format("ddd, DD MMM YYYY");
                      return (
                        <>
                          <img
                            onClick={params.inputProps.onClick}
                            style={{
                              height: "22px",
                              width: "22px",
                              color: "#ADA6AE",
                            }}
                            src={CalendarStartIcon}
                          />
                          <TextField
                            fullWidth
                            placeholder={"Tanggal Keberangkatan"}
                            variant="filled"
                            className={classes.departureDateTextField}
                            {...params}
                          />

                          {/* <CalendarTodayOutlinedIcon
                            onClick={params.inputProps.onClick}
                            style={{ padding: "15px 15px", color: "#ADA6AE" }}
                          /> */}
                        </>
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* Toggle to display Return Ticiket option */}
              <SliderButton
                returnTicket={returnTicket}
                setReturnTicket={setReturnTicket}
              />
            </div>
            {/* Train Ticiket Return Date */}
            {returnTicket && (
              <div style={{ marginTop: "16px" }}>
                <div
                  className="item-center"
                  style={
                    error && isDateAfter(departureDate, arrivalDate)
                      ? {
                          display: "flex",
                          borderBottom: "1px solid",
                          borderColor: "#d32f2f",
                          height: "56px",
                          borderRadius: "4px",
                          // background: "#F7F6F6",
                        }
                      : {
                          display: "flex",
                          height: "56px",
                          borderRadius: "4px",
                          // background: "#F7F6F6",
                        }
                  }
                >
                  {/* <CalendarTodayOutlinedIcon className={"color-app-blue"} /> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={id}>
                    <MobileDatePicker
                      InputProps={{
                        className: "bg-white",
                        disableUnderline: true,
                        style: {
                          height: "56px",
                          borderRadius: "10px",
                          // background: "#F7F6F6",
                          fontWeight: "bold",
                        },
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      disablePast
                      DialogProps={{ className: classes.selectDepartureDate }}
                      value={arrivalDate}
                      cancelText={""}
                      label={
                        !(
                          openStartStationDropDown ||
                          openStopStationDropDown ||
                          openPassengerClass ||
                          openPassenger
                        )
                          ? "Tanggal Pulang"
                          : ""
                      }
                      maxDate={maxSelectableDate}
                      minDate={departureDate}
                      onChange={(date) => {
                        setArrivalDate(date);
                      }}
                      renderInput={(params) => {
                        params.inputProps.value = moment(
                          params.inputProps.value,
                          "DD/MM/YYYY"
                        ).format("ddd, DD MMM YYYY");
                        return (
                          <>
                            <img
                              onClick={params.inputProps.onClick}
                              style={{
                                height: "22px",
                                width: "22px",
                                color: "#ADA6AE",
                              }}
                              src={CalendarEndIcon}
                            />
                            <TextField
                              variant="filled"
                              fullWidth
                              className={classes.departureDateTextField}
                              {...params}
                            />

                            {/* <CalendarTodayOutlinedIcon
                              onClick={params.inputProps.onClick}
                              style={{ padding: "15px 15px", color: "#ADA6AE" }}
                            /> */}
                          </>
                        );
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <span
                  style={{
                    display:
                      error && isDateAfter(departureDate, arrivalDate)
                        ? ""
                        : "none",
                    color: "#d32f2f",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >{`Pulang date must be >= Pergi date`}</span>
              </div>
            )}

            {/* Passangers */}
            <div style={{ marginTop: "16px" }} className="flex item-center">
              <img
                src={PeopleIcon}
                alt="passengers"
                onClick={() => setOpenPassenger(!openPassenger)}
              />
              <TextField
                id={"Penumpang-value"}
                fullWidth
                autoComplete={"off"}
                label={
                  !(
                    openStartStationDropDown ||
                    openStopStationDropDown ||
                    openPassengerClass ||
                    openPassenger
                  )
                    ? "Penumpang"
                    : ""
                }
                variant="filled"
                value={getPassengerNumber()}
                // error={error && !stopStation}
                className={classes.stationDropdownButton}
                onFocus={() => setOpenPassenger(!openPassenger)}
                InputProps={{
                  className: "bg-white",
                  disableUnderline: true,
                  endAdornment: (
                    <div>
                      <KeyboardArrowDownIcon
                        color="#1A1A1A"
                        className={"bg-white"}
                        onClick={() => setOpenPassenger(!openPassenger)}
                      />
                    </div>
                  ),
                }}
                inputProps={{
                  style: { fontWeight: "bold" },
                  readOnly: true,
                }}
              />
            </div>

            {/* Class */}
            <div style={{ marginTop: "16px" }} className="flex item-center">
              <img
                src={SeatIcon}
                style={{ height: "22px", width: "22px" }}
                alt="passengers"
                onClick={() => setOpenPassengerClass(!openPassengerClass)}
              />
              <TextField
                id={"Penerbangan-value"}
                fullWidth
                autoComplete={"off"}
                label={
                  !(
                    openStartStationDropDown ||
                    openStopStationDropDown ||
                    openPassengerClass ||
                    openPassenger
                  )
                    ? "Kelas Penerbangan"
                    : ""
                }
                variant="filled"
                value={passengerClassMap[passengerClass] || ""}
                // error={error && !stopStation}
                className={classes.stationDropdownButton}
                onFocus={() => {
                  setOpenPassengerClass(!openPassengerClass);
                }}
                InputProps={{
                  className: "bg-white",
                  disableUnderline: true,
                  endAdornment: (
                    <div>
                      <KeyboardArrowDownIcon
                        color="#1A1A1A"
                        className={" bg-white"}
                        onClick={() =>
                          setOpenPassengerClass(!openPassengerClass)
                        }
                      />
                    </div>
                  ),
                }}
                inputProps={{
                  style: { fontWeight: "bold" },
                  readOnly: true,
                }}
              />
            </div>
            <div className="disclaimer">
              <Typography className="terms">
                Produk atau jasa ini disediakan dan menjadi tanggung jawab
                Traveloka. Untuk layanan Customer Service Traveloka, hubungi 021
                29103300.</Typography>
                <Typography className="terms">Cek Syarat & Ketentuan lengkap{" "}
                <a
                  style={{ color: "#007DFE", textDecoration: "none" }}
                  target="_blank"
                  href = "https://bmri.id/sukhatncxtf"
                >
                  di sini.
                </a>
              </Typography>
            </div>

            <Button
              children={"Cari Tiket Pesawat"}
              fullWidth
              variant={"text"}
              className={"submitButton"}
              onClick={handleSubmit}
              id="CariTiketPesawat"
            />
          </div>
        </div>
      </div>

      {openStartStationDropDown && (
        <SelectStationDropDown
          list={airports}
          slideOpen={openStartStationDropDown}
          setOpenStationDropDown={setOpenStartStationDropDown}
          setStationName={setStartStation}
          title={"Asal"}
        />
      )}
      {openStopStationDropDown && (
        <SelectStationDropDown
          list={airports}
          slideOpen={openStopStationDropDown}
          setOpenStationDropDown={setOpenStopStationDropDown}
          setStationName={setStopStation}
          title={"Tujuan"}
        />
      )}
      {openPassenger && (
        <SelectPassengerPopUp
          slideOpen={openPassenger}
          passengers={passengers}
          setPassengers={setPassengers}
          setOpenPassenger={setOpenPassenger}
        />
      )}

      {openPassengerClass && (
        <SelectPassengerClass
          value={passengerClass}
          slideOpen={openPassengerClass}
          setOpenPassengerClass={setOpenPassengerClass}
          setPassengerClass={setPassengerClass}
        />
      )}
    </div>
  );
};

export default StationSelection;

const useStyle = makeStyles({
  selectDepartureDate: {
    '& .MuiDialogActions-root>:first-of-type': {
      display: 'none'
    },
    // giv a line after the date displayed at top in Date Picker
    "& .PrivatePickersToolbar-root": {
      borderBottom: "1px solid #DDD !important",
    },
    // removed the edit button which changed the view of the date picker
    "& .MuiPickersToolbar-penIconButton": {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    "& .MuiButton-root": {
      background: "#007DFE",
      color: "#FFF",
      "&:hover": {
        background: "#007DFE",
      },
    },
    // background color applied to date when selected and also when seledted and hover on it
    "& .Mui-selected": {
      backgroundColor: "#007DFE !important",
      "&:hover": {
        backgroundColor: "#007DFE !important",
      },
    },
  },
  departureDateTextField: {
    // style APPLIED to TEXTFIELD
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px !important",
      fontSize: "16px !important",
      fontWeight: "bold",
    },
  },
  stationDropdownButton: {
    "& .MuiFilledInput-root": {
      // backgroundColor: "#f7f6f6",
    },
    // marginTop: "16px",
  },
  noBorder: {
    border: "none",
  },
});
